<template>
  <footer class="footer-box">Copyright ©2024 深圳市明慧科技有限公司 粤ICP备2023074364</footer>
</template>

<script>
export default {};
</script>

<style scoped>
.footer-box {
  width: 100%;
  font-size: 1rem;
}
@media (max-width: 789px) {
  .footer-box {
  width: 80vw;
  font-size: 0.8rem;
  margin-left: 15vw;
}
}
</style>

