<template>
  <div class="products">
    <div class="products-item" v-for="item in products" :key="item.id">
      <div class="item-img">
        <img :src="item.img" alt="" />
      </div>
      <div class="item-amount">￥{{ item.amount }}</div>
      <div class="item-name">{{ item.name }}</div>
      <el-button type="danger" size="mini" class="item-btn" @click="goLogin">立即开通</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Products",
  data() {
    return {
      products: [
        { name: "爱奇艺视频月卡", amount: 28.8, img: require("@/assets/products/aiqiyi.png"), id: 1 },
        { name: "腾讯视频月卡", amount: 28.8, img: require("@/assets/products/tx.png"), id: 2 },
        { name: "优酷视频月卡", amount: 28.8, img: require("@/assets/products/yk.png"), id: 3 },
        { name: "芒果视频月卡", amount: 28.8, img: require("@/assets/products/mg.png"), id: 4 },
        { name: "酷狗音乐月卡", amount: 13.8, img: require("@/assets/products/kg.png"), id: 5 },
        { name: "哔哩哔哩月卡", amount: 23.8, img: require("@/assets/products/bilbil.png"), id: 6 },
        { name: "喜马拉雅月卡", amount: 22.8, img: require("@/assets/products/xmly.png"), id: 7 },
        { name: "网易云音乐月卡", amount: 16.8, img: require("@/assets/products/wyy.png"), id: 8 },
        { name: "百度网盘SVIP月卡", amount: 28.8, img: require("@/assets/products/baidu.png"), id: 9 },
        { name: "作业帮VIP月卡", amount: 18.8, img: require("@/assets/products/zyb.png"), id: 10 },
        { name: "酷我音乐月卡", amount: 13.8, img: require("@/assets/products/kw.png"), id: 11 },
        { name: "哈啰单车月卡", amount: 23.8, img: require("@/assets/products/hl.png"), id: 12 },
        { name: "keep会员月卡", amount: 23.8, img: require("@/assets/products/keep.png"), id: 13 },
        { name: "迅雷SVIP月卡", amount: 28.8, img: require("@/assets/products/xl.png"), id: 14 },
        { name: "懒人听书月卡", amount: 13.8, img: require("@/assets/products/lr.png"), id: 15 },
      ],
    };
  },
  methods: {
    goLogin() {
      this.$router.push({ path: "/login" });
    },
  },
  created() {
    console.log(this.$store);
  },
};
</script>

<style lang="scss" scoped>
.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* 保证商品项之间有间距 */
  align-items: flex-start;
  width: 100%;
  margin-top: 2rem;
  gap: 1rem; /* 设置商品项之间的间距 */
  margin-bottom: 2rem;

  .products-item {
    width: 12rem; /* 固定宽度 */
    height: 12rem; /* 固定高度 */
    border: 1px solid #c4c4c4;
    border-radius: 1rem;
    display: flex;
    flex-direction: column; /* 确保商品项内部是垂直排列 */
    justify-content: space-between; /* 保证各部分之间有足够间距 */
    align-items: center;
    padding: 0.5rem;
    box-sizing: border-box; /* 包括padding和border的总宽度计算 */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
    transition: all 0.3s ease; /* 鼠标悬停时的平滑过渡 */
  }
  .products-item:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* 鼠标悬停时增加阴影 */
    transform: translateY(-5px); /* 鼠标悬停时轻微上浮 */
  }
  .item-img {
    width: 5rem;
    height: 5rem;
  }

  .item-img img {
    max-width: 100%;
    height: auto; /* 保证图片按比例缩放 */
    object-fit: contain; /* 确保图片在容器内适应 */
  }
  .item-amount {
    color: #e4393c;
    font-weight: 600;
  }

  .item-amount,
  .item-name {
    font-size: 1rem;
  }

  .item-btn {
    text-align: center;
  }
}
</style>
