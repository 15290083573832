<template>
  <div class="home">
    <!-- 页面加载中效果 -->
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>
    <div class="home-info" v-if="!isLoading">
      <div class="info-text">
        <p class="info-title">深圳市明慧科技有限公司</p>
        <span class="info-text-content">
          深圳市明慧科技有限公司，成立于2015年，位于广东省深圳市，是一家以从事其他服务业为主的企业。<br />公司始终秉持合作共赢理念，并坚持贯彻三个保持一个中心。保持一流的技术领先性；保持高效的一流的运营团队，保持24小时响应的客服服务团队，以客户需求为中心的市场化响应机制。
        </span>
      </div>
      <div class="info-img">
        <!-- 懒加载图片 -->
        <img :src="infoImg" alt="Info Image" />
      </div>
    </div>
    <div class="home-vision" v-if="!isLoading">
      <div class="vision-img">
        <img :src="vision_1" alt="Vision 1" />
        <div class="vision-text">
          <span class="vision-title">企业愿景</span>
          <span class="vision-line"></span>
          <span class="vision-tabs">成为一流的增值业务服务商</span>
        </div>
      </div>
      <div class="vision-img spacing">
        <img :src="vision_2" alt="Vision 2" />
        <div class="vision-text">
          <span class="vision-title">企业使命</span>
          <span class="vision-line"></span>
          <span class="vision-tabs">为客户提供全案服务</span>
        </div>
      </div>
      <div class="vision-img">
        <img :src="vision_3" alt="Vision 3" />
        <div class="vision-text">
          <span class="vision-title">企业价值观</span>
          <span class="vision-line"></span>
          <span class="vision-tabs">诚信、服务、一流、创新</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      isLoading: true, // 控制loading的显示
      infoImg: require("@/assets/img/infoImg.jpg"),
      vision_1: require("@/assets/img/vision_1.jpg"),
      vision_2: require("@/assets/img/vision_2.jpg"),
      vision_3: require("@/assets/img/vision_3.jpg"),
    };
  },
  mounted() {
    // 模拟图片加载完成，设定延时
    setTimeout(() => {
      this.isLoading = false;
    }, 800); // 2秒后加载完成
  },
};
</script>

<style lang="scss" scoped>
.home {
  margin-top: 2rem;
  padding: 2vh 6vw;
  text-align: center;
}

/* Loading效果 */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.home-info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #2f363c;
}

.info-text {
  flex: 1;
  text-align: left;
  padding-right: 2rem;
  .info-title {
    font-size: 1.3rem;
    font-weight: 600;
  }
  .info-text-content {
    font-size: 1rem;
  }
}

.info-img {
  flex: 1;
  width: 100%;
  height: auto;
}

.info-img img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.home-vision {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;
  .vision-img {
    flex: 1;
    position: relative;
    img {
      width: 100%;
      height: auto;
    }
    .vision-text {
      position: absolute;
      top: 2rem;
      left: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: baseline;
      color: #ffffff;
      width: 70%;
      height: 20%;
      .vision-title {
        font-size: 1.2rem;
        font-weight: 600;
      }
      .vision-line {
        width: 3rem;
        height: 0.2rem;
        background-color: #ff0000;
      }
      .vision-tabs {
        font-size: 0.9rem;
      }
    }
  }

  .spacing {
    margin: 0rem 1rem;
  }
}

@media (max-width: 890px) {
  .home {
    padding: 2vh 0vw;
  }
}
@media (max-width: 789px) {
  .home {
    margin-top: 0rem;
  }
  .home-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    width: 100%;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #2f363c;
    .info-text {
      padding-right: 0;
      margin-bottom: 1rem;
    }
  }

  .home-vision {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    width: 100%;
    margin-top: 0.5rem;

    .vision-img {
      flex: 1;
      width: 100%;
      height: auto;
      margin-left: 0;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
