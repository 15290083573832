<template>
  <div>
    <!-- Navbar (For Larger Screens) -->
    <header class="navbar" v-if="!isMobile">
      <div class="navbar-left">
        <div class="logo">
          <img :src="srcImg" alt="Logo" />
        </div>
        <ul class="navbar-list">
          <li>
            <router-link to="/" exact active-class="active">首页</router-link>
          </li>
          <li>
            <router-link to="/products" active-class="active">权益产品</router-link>
          </li>
          <li>
            <router-link to="/register" active-class="active">账号注册</router-link>
          </li>
        </ul>
      </div>
      <div class="logo-box">
        <router-link to="/login" active-class="active">登录</router-link>
        <i class="el-icon-user"></i>
      </div>
    </header>

    <!-- Sidebar (For Small Screens) -->
    <div class="sidebar" v-if="isMobile">
      <div class="logo">
        <img :src="srcImg" alt="Logo" />
      </div>
      <ul class="sidebar-list">
        <li>
          <router-link to="/" exact active-class="active">首页</router-link>
        </li>
        <li>
          <router-link to="/products" active-class="active">权益产品</router-link>
        </li>
        <li>
          <router-link to="/register" active-class="active">注册</router-link>
        </li>
        <li>
          <router-link to="/login" active-class="active">登录</router-link>
          <i class="el-icon-user"></i>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      srcImg: require("@/assets/logo.png"),
      windowWidth: window.innerWidth, // 初始化窗口宽度
      isMobile: false, // 是否为移动端
    };
  },
  watch: {
    // 监听窗口宽度，判断是否为移动端
    windowWidth(newWidth) {
      this.isMobile = newWidth < 768;
    },
    // 监听路由变化，更新导航项的高亮
    $route(to, from) {
      this.updateActiveLink();
    },
  },
  mounted() {
    // 监听页面尺寸变化
    window.addEventListener("resize", this.handleResize);
    // 初始时更新高亮
    this.updateActiveLink();
  },
  beforeDestroy() {
    // 清除监听
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      // 更新窗口宽度
      this.windowWidth = window.innerWidth;
    },
    updateActiveLink() {
      // 根据当前路由路径更新导航项的高亮
      this.activeLink = this.$route.path;
    },
  },
};
</script>

<style scoped>
/* Navbar 样式（适用于大屏） */
.navbar {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  padding: 0.5rem 1rem;
  width: 100%;
  border-bottom: 1px solid #cdcdcd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-left {
  display: flex;
  justify-content: baseline;
  align-items: center;
}

.logo {
  width: 3rem;
  height: 3rem;
}

.logo img {
  width: 100%;
  height: 100%;
}

.navbar-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.logo-box {
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  margin-right: 2rem;
}

.navbar-list li {
  margin: 0 2rem;
}

.navbar-list a {
  color: #333333;
  text-decoration: none;
  font-size: 16px;
}

.navbar-list a:hover {
  text-decoration: underline;
}

/* 激活链接样式 */
.navbar-list a.active,
.sidebar-list a.active {
  color: #16b777;
  border-bottom: 2px solid #16b777;
}

/* Sidebar 样式（适用于小屏） */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 3.5rem;
  height: 100%;
  background-color: #333;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.2rem;
  z-index: 999;
  transition: transform 0.3s ease-in-out;
}

.sidebar .logo img {
  width: 2rem;
  height: 2rem;
  margin: 2rem 0;
}

.sidebar-list {
  list-style: none;
  padding: 2rem 0;
  margin: 1rem 0;
}

.sidebar-list li {
  margin: 1rem 0;
}

.sidebar-list a {
  color: white;
  text-decoration: none;
  font-size: 0.8rem;
}

.sidebar-list a:hover {
  text-decoration: underline;
}

/* 移动端样式 */
@media (max-width: 768px) {
  .navbar {
    display: none; /* 移动端隐藏顶部导航 */
  }
  .sidebar {
    transform: translateX(0);
  }
}
</style>
